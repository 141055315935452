/**
 * User Model Helper – enriching data to template representation.
 * @param target – User / Rioter
 */
export default (target) => {
  const _passThrough = (user) => {
    if (Array.isArray(user)) {
      return user.map((o) => _passThrough(o));
    }

    user.name = `${user.first_name} ${user.last_name}`;
    user.timezone = `GMT${user?.utc_offset > 0 ? "+" : ""}${user.utc_offset}`;

    const { user: me } = storeToRefs(useUser());
    user.goToDetailPage = async () =>
      me.value?.id && me.value?.id === user?.id
        ? await navigateTo("/profile/")
        : await navigateTo(`/profile/${user.id}`);

    // replace null & none -> 0
    if (user.rank === null) {
      user.rank = 0;
    }
    if (user.rank !== null && typeof user.rank === "object") {
      user.rank = useMapValues(user.rank, (v) => parseInt(v, 10) || 0);
    }

    return user;
  };

  return _passThrough(target);
};
